// eslint-disable-next-line no-restricted-imports
import {
  CreateLegalClientHeadByAdminDto_Input,
  MutationInput_LegalClientHeadController_Create_Input_PepConnectionType as PepConnectionTypeApi,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { CreateLegalClientHeadParams } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseHeadPositionParam } from '../parseParam';

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi.Self,
};

export const parseCreateLegalClientHeadParams = (
  params: CreateLegalClientHeadParams
): CreateLegalClientHeadByAdminDto_Input => ({
  email: params.email,
  legalClientId: params.legalClientId,
  versionNumber: params.versionNumber,
  firstName: params.firstName,
  lastName: params.lastName,
  reason: params.reason,
  middleName: params.middleName,
  dateOfBirth: formatToISODate(params.dateOfBirth),
  citizenships: params.citizenships,

  registrationAddress: params.registrationAddress,
  residentialAddress: params.residentialAddress,
  taxResidences: params.taxResidences,

  connectedPepInfo: params.connectedPepInfo,

  pepConnectionType: relationType[params.pepConnection],

  position: parseHeadPositionParam(params.position)!,
});
