import {
  parseAddressValue,
  parseCompanyStateRegistrationValue,
} from 'modules/client/common/helpers';

import { LegalClientLegalHeadFormType } from '../../forms/legalClientLegalHeadForm';
import { LegalClientHeadPositionFormType } from '../../forms/positionInCompanyForm';

export const getLegalClientLegalHeadInitialValue =
  (): LegalClientLegalHeadFormType => ({
    reasonChange: { reason: '' },

    companyTaxResidences: [{ country: null, taxNumber: '' }],

    companyRegistrationAddress: parseAddressValue(),
    companyResidentialAddress: parseAddressValue(),

    position: {
      headType: null,
      position: '',
      positionConfirmationFiles: [],
      body: null,
    } as LegalClientHeadPositionFormType['position'],

    companyName: { fullName: '', shortName: '' },

    companyStateRegistration: parseCompanyStateRegistrationValue(undefined),
  });
