import { ErrorLayout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks/useScrollToTop';

import { useLegalClientBranch } from '../hooks';
import { LegalClientBranchView } from '../views/LegalClientBranchView';

export const LegalClientBranchContainer = () => {
  const {
    loading,
    legalClientBranch,
    countries,
    countriesLoading,
    error,
    initialValues,
    submitLoading,
    breadcrumbsTitles,
    deleteLoading,
    archiveLoading,
    dearchiveLoading,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onShowVersionHistory,
    onDelete,
    onArchive,
    onDearchive,
  } = useLegalClientBranch();

  useScrollToTop([]);

  if (error || (!legalClientBranch && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !legalClientBranch) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientBranchView
      archiveLoading={archiveLoading}
      breadcrumbsTitles={breadcrumbsTitles}
      countries={countries}
      countriesLoading={countriesLoading}
      dearchiveLoading={dearchiveLoading}
      deleteLoading={deleteLoading}
      initialValues={initialValues}
      lockClientIfPossible={lockClientIfPossible}
      submitLoading={submitLoading}
      unlockClient={unlockClient}
      onArchive={onArchive}
      onDearchive={onDearchive}
      onDelete={onDelete}
      onShowVersionHistory={onShowVersionHistory}
      onSubmit={onSubmit}
    />
  );
};
