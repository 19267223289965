import { useClients as useClientsApi } from 'api/requests';
import { useQueryListData } from 'hooks';
import { ROUTES, generatePath } from 'libs/navigation';
import { TableSetRowHref } from 'libs/ui';

import { ClientsListItem } from '../types';

const LIMIT = 50;

export const useClients = () => {
  const {
    list: clients,
    rowCount,
    loading,
    setFilter,
    sort,
    page,
    filter,
    onPaginationChange,
    onSortChange,
  } = useQueryListData(useClientsApi, {
    limit: LIMIT,
    filterParams: [
      'search',
      'onboardingStatuses',
      'riskLevels',
      'locationCountries',
      'registrationCountries',
    ],
  });

  const setRowHref: TableSetRowHref<ClientsListItem> = (client) =>
    generatePath(ROUTES.clients.personClients.personClient.fullPath, {
      clientId: client.id,
    });

  return {
    clients,
    loading,
    sort,
    page,
    rowCount,
    limit: LIMIT,
    filter,
    setFilter,
    setRowHref,
    onSortChange,
    onPaginationChange,
  };
};
