import { TFunc } from 'libs/i18n';
import { string } from 'yup';

export const email = (t: TFunc) =>
  string()
    .email()
    .matches(/^(?!\.)[\w-.+]+@([\w-]+\.)+[a-zA-Z]{2,50}$/, {
      message: t('validation.email'),
    })
    .max(50);
