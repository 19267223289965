import { CreateLegalClientHeadParams } from 'api/types/params';
import { formatToISODate, yesOrNot } from 'helpers';
import {
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseCitizenshipsParam,
  parseAddressWithFilesParam,
} from 'modules/client/common/helpers';

import { LegalClientHeadFormType } from '../../forms/legalClientHeadForm';

export const getLegalClientHeadSubmitData = (
  legalClientId: string,
  versionNumber: number,
  values: LegalClientHeadFormType
): CreateLegalClientHeadParams => {
  const addressParam = parseAddressWithFilesParam(values);

  return {
    versionNumber,
    email: values.personalData?.email!,
    legalClientId,
    reason: values.reasonChange.reason,
    firstName: values.personalData!.firstName!,
    lastName: values.personalData!.lastName!,
    middleName: values.personalData!.middleName ?? null,
    dateOfBirth: values.personalData!.dateOfBirth!,
    citizenships: parseCitizenshipsParam(values),

    registrationAddress: addressParam.registrationAddress,
    residentialAddress: addressParam.residentialAddress,

    taxResidences: parseTaxResidencyParam(values),

    pepConnection: values.pepConnection.relation?.value!,
    connectedPepInfo: parsePepConnectionParam(values),

    position: {
      positionInCompany: values.position.position,
      isFirstHead: yesOrNot(values.position.firstHead?.value) ?? false,
      legalClientBodyId: values.position.body?.value!,
      expirationDate: values.position.dateOfExpiration
        ? formatToISODate(values.position.dateOfExpiration)
        : undefined,
      positionConfirmationFileIds:
        values.position.positionConfirmationFiles.map(({ id }) => id!),
    },
  };
};
