// eslint-disable-next-line no-restricted-imports
import {
  UpdateLegalClientByAdminDto_Input,
  MutationInput_LegalClientController_UpdateById_Input_RiskLevel,
  MutationInput_LegalClientController_UpdateById_Input_InformationUpdateFrequency,
  MutationInput_LegalClientController_UpdateById_Input_Tariff,
} from 'api/generated/graphql';
import { UpdateLegalClientParams } from 'api/types/params';
import { formatToISODate } from 'helpers';

import {
  parseCurrencyParam,
  parseDebtsParam,
  parseInformationUpdateFrequency,
  parseOpenAccountOrderParam,
  parseRiskLevel,
} from '../client/parseParam';
import {
  parseManagementTypeParam,
  parseServiceContractParam,
} from '../parseParam';

import {
  parseFinancialMonitoringParam,
  parseLegalClientBusinessRelationsParam,
  parseLegalClientFinancingSourcesParam,
  parseLegalClientOrderSubmissionContactsParam,
  parseOrganizationalAndLegalFormParam,
  parseCategoryTypeParam,
  parseEconomicSectorTypeParam,
  parseConstitutionalDocumentParam,
} from './parseParam';

export const parseUpdateLegalClientParams = (
  params: UpdateLegalClientParams
): UpdateLegalClientByAdminDto_Input => ({
  versionNumber: params.versionNumber,
  reason: params.reason,
  fullName: params.fullName,
  shortName: params.shortName,
  fullNameEng: params.fullNameEng,
  shortNameEng: params.shortNameEng,
  debts: parseDebtsParam(params.debts),
  bankAccounts: params.bankAccounts,

  financingSources: parseLegalClientFinancingSourcesParam(
    params.financingSources
  ),
  legalAddress: params.legalAddress,
  actualAddress: params.actualAddress,

  taxResidences: params.taxResidences,

  orderSubmissionContacts: parseLegalClientOrderSubmissionContactsParam(
    params.orderSubmissionContacts
  ),

  withdrawalAccounts: params.withdrawalAccounts.map((p) => ({
    ...p,
    currency: parseCurrencyParam(p.currency),
  })),

  accreditedInvestorStatus: params.accreditedInvestorStatus,

  riskLevel:
    parseRiskLevel<MutationInput_LegalClientController_UpdateById_Input_RiskLevel>(
      params.riskLevel
    ),
  informationUpdateFrequency:
    parseInformationUpdateFrequency<MutationInput_LegalClientController_UpdateById_Input_InformationUpdateFrequency>(
      params.informationUpdateFrequency
    ),
  riskLevelReviewDate: params.riskLevelReviewDate
    ? formatToISODate(params.riskLevelReviewDate)
    : null,
  lastInformationUpdateDate: params.lastInformationUpdateDate
    ? formatToISODate(params.lastInformationUpdateDate)
    : null,
  lastOperationMonitoringResults: params.lastOperationMonitoringResults ?? null,
  usedServices: params.usedServices ?? null,

  code: params.code,
  centralDepositoryAccountNumber: params.centralDepositoryAccountNumber,
  centralDepositoryAccountNumberOpeningDate:
    params.centralDepositoryAccountNumberOpeningDate
      ? formatToISODate(params.centralDepositoryAccountNumberOpeningDate)
      : null,
  personalAccountNumber: params.personalAccountNumber,
  personalAccountOpeningDate: params.personalAccountNumberOpeningDate
    ? formatToISODate(params.personalAccountNumberOpeningDate)
    : params.personalAccountNumberOpeningDate,
  managerId: params.managerId,

  additionalInformationFileIds: params.additionalInformationFileIds,
  additionalDocumentFileIds: params.additionalDocumentFileIds,

  isLicensed: params.license.isLicensed,
  activityTypes: params.activityTypes,
  OKPOCode: params.OKPOCode,

  licenses: params.license.licenses,
  businessRelationship: parseLegalClientBusinessRelationsParam(
    params.businessRelationship
  ),

  stateRegistration: params.stateRegistration,
  constitutionalDocument: parseConstitutionalDocumentParam(
    params.constitutionalDocument
  ),

  contacts: params.contacts,
  financialMonitoring: parseFinancialMonitoringParam(
    params.financialMonitoring
  ),
  organizationalAndLegalForm: parseOrganizationalAndLegalFormParam(
    params.organizationalAndLegalForm
  ),
  registrationPlace: params.registrationPlace,

  openAccountOrder: parseOpenAccountOrderParam(params.openAccountOrder),

  category: parseCategoryTypeParam(params.category),
  economicSector: parseEconomicSectorTypeParam(params.economicSector),
  managementType: parseManagementTypeParam(params.managementType),
  isFinancialMonitoringSubject: params.isFinancialMonitoringSubject,
  isKazakhstanResident: params.isKazakhstanResident,
  taxBenefits: params.taxBenefits,

  serviceContract: parseServiceContractParam(params.serviceContract),

  tariff: params.tariff
    ? MutationInput_LegalClientController_UpdateById_Input_Tariff[params.tariff]
    : undefined,
});
