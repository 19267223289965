export enum PermissionType {
  AdminList = 'AdminList',
  AdminRead = 'AdminRead',
  AdminEdit = 'AdminEdit',
  AdminCreate = 'AdminCreate',
  AdminResetPassword = 'AdminResetPassword',
  AdminResendInvite = 'AdminResendInvite',
  AdminActivate = 'AdminActivate',
  AdminDeactivate = 'AdminDeactivate',
  AdminRevokeOtpVerification = 'AdminRevokeOtpVerification',
  LegalClientBeneficiaryDeleteOne = 'LegalClientBeneficiaryDeleteOne',
  LegalClientHeadDeleteOne = 'LegalClientHeadDeleteOne',
  LegalClientLegalHeadDeleteOne = 'LegalClientLegalHeadDeleteOne',
  LegalClientLegalHeadFirstHeadDeleteOne = 'LegalClientLegalHeadFirstHeadDeleteOne',
  LegalClientBodyDeleteOne = 'LegalClientBodyDeleteOne',
  LegalClientBranchDeleteOne = 'LegalClientBranchDeleteOne',
  SystemSettingGetOne = 'SystemSettingGetOne',
  SystemSettingUpdateOne = 'SystemSettingUpdateOne',
}
