import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { ApolloError, ServerError } from '@apollo/client';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { parseServerError } from './parseServerError';

/** Handle apollo error */
export const useError = (error?: ApolloError, notifyUnknownError = true) => {
  const notify = useNotify();
  const { t } = useTranslation();

  return useMemo(() => {
    const serverError = parseServerError(error);
    if (serverError === false) {
      error!.message = t('common.standardError');

      if (
        notifyUnknownError &&
        (error?.networkError as ServerError)?.statusCode !== 401
      ) {
        notify.error(error);
      }
      return undefined;
    }
    return serverError;
  }, [error, notify, notifyUnknownError, t]);
};
