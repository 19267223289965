import { Stack } from 'libs/ui';

import { AccountSystemSettings } from './AccountSystemSettings';
import { OnboardingSettings } from './OnboardingSettings';

export const SystemSettingsContent = () => (
  <Stack spacing={48}>
    <AccountSystemSettings />
    <OnboardingSettings />
  </Stack>
);
