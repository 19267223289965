import { Typography } from 'libs/ui';
import { Colors } from 'libs/ui/theme';

import { BranchesItem } from '../types';

export const renderText = (item: BranchesItem, text: string) => (
  <Typography
    className="ellipsisText"
    color={item.archived ? Colors.gray250 : undefined}
  >
    {text}
  </Typography>
);
