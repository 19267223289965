import { useMemo, useRef, useState } from 'react';

import { LegalClientInnerItemEntity } from 'api/types/entity';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { Button, TableSetRowHref } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import {
  DialogDeleteAndReasonChange,
  Section,
} from 'modules/client/common/components';

import { FirstHeadArchiveDialog } from './FirstHeadArchiveDialog';
import { HeadItem, HeadsTable } from './HeadsTable';

export interface CompanyFirstHeadsSectionProps {
  firstHeads: LegalClientInnerItemEntity[];
  onClickHead?: (id: string) => void;
  setFirstHeadRowHref?: (id: string) => string | undefined;
  onClickAddFirstHead?: () => void;
  onDeleteFirstHead?: (id: string, reason: string) => Promise<void>;
  onArchiveFirstHead?: (id: string, reason: string) => Promise<void>;
  onDearchiveFirstHead?: (id: string, reason: string) => Promise<void>;
}

export const CompanyFirstHeadsSection = ({
  firstHeads,
  onClickHead,
  onClickAddFirstHead,
  onDeleteFirstHead,
  onArchiveFirstHead,
  onDearchiveFirstHead,
  setFirstHeadRowHref,
}: CompanyFirstHeadsSectionProps) => {
  const { t } = useTranslation();

  const { readOnly } = useFormContext();

  const activeHeadItem = useRef<HeadItem>();

  const [dialogLoading, setDialogLoading] = useState(false);

  const {
    isOpen: isOpenDeleteDialog,
    onClose: onCloseDeleteDialog,
    open: openDialogReason,
  } = useDialog();
  const { open: openArchiveDialog, ...archiveDialogProps } = useDialog();

  const items: HeadItem[] = useMemo(() => {
    const result = firstHeads.map((h) => ({
      id: h.id,
      name: h.name,
      city: h.registrationCity || '',
      location: h.location || '',
      email: h.email || '',
      createdAt: h.createdAt,
      archived: h.archived,
      documentDateOfExpiration:
        h.positionInCompanyConfirmationDocumentDateOfExpiration,
    }));

    result.sort((a, b) =>
      a.archived === b.archived
        ? b.createdAt.getTime() - a.createdAt.getTime()
        : a.archived
        ? 1
        : -1
    );

    return result;
  }, [firstHeads]);

  const onCellClick = (item: HeadItem) => {
    if (item) {
      onClickHead?.(item.id);
    }
  };

  const setRowHref: TableSetRowHref<HeadItem> = (item) =>
    setFirstHeadRowHref?.(item.id);

  const onClickDelete = (row: HeadItem) => {
    activeHeadItem.current = row;
    openDialogReason();
  };

  const onClickArchive = (row: HeadItem) => {
    activeHeadItem.current = row;
    openArchiveDialog();
  };

  const onDelete = async (reason: string) => {
    setDialogLoading(true);
    if (activeHeadItem.current && onDeleteFirstHead) {
      try {
        await onDeleteFirstHead(activeHeadItem.current.id, reason);
      } catch (error) {
        /* empty */
      }
    }
    setDialogLoading(false);
    onCloseDeleteDialog();
  };

  return (
    <Section
      headerRight={
        !readOnly &&
        onClickAddFirstHead && (
          <Button
            label={t('client.legal.companyHeads.addHead')}
            mode="medium"
            startIcon={<Icon.Plus />}
            onClick={onClickAddFirstHead}
          />
        )
      }
      title={t('client.legal.legalClientLegalHead.firstHeads.title')}
    >
      <HeadsTable
        editable={!readOnly}
        items={items}
        setRowHref={setRowHref}
        onCellClick={onCellClick}
        onClickArchive={onClickArchive}
        onClickDelete={onClickDelete}
      />

      <DialogDeleteAndReasonChange
        deleteLoading={dialogLoading}
        isOpen={isOpenDeleteDialog}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
      />

      <FirstHeadArchiveDialog
        activeHeadItem={activeHeadItem.current}
        onArchive={onArchiveFirstHead}
        onDearchive={onDearchiveFirstHead}
        {...archiveDialogProps}
      />
    </Section>
  );
};
