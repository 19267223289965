import { useMemo } from 'react';

import { lodash } from 'helpers';
import {
  useConvertDateToUserTimezone,
  useDownloadFile,
  usePreviewFile,
} from 'hooks';
import { useTranslation } from 'libs/i18n';
import { Table } from 'libs/ui';

import { LocalFileEntity } from '../../types';

import { useColumns } from './columns';
import { DocumentItemType, DocumentOptionType } from './types';

interface Props {
  files: FileType[];
  editable?: boolean;
  isError?: boolean;
  noRowsLabel?: string;
  onClickName?: (item: DocumentItemType) => void;
  onClickDownload?: (id: string) => void;
  onClickDelete?: (id: string) => void;
}

type FileType = LocalFileEntity & { loading?: boolean } & { id: string };

export const DocumentsTable = ({
  files,
  editable,
  isError,
  noRowsLabel,
  onClickName,
  onClickDownload,
  onClickDelete,
}: Props) => {
  const { t } = useTranslation();

  const { downloadFile } = useDownloadFile();
  const { previewFile } = usePreviewFile();

  const { convertDateToUserTimezone } = useConvertDateToUserTimezone();

  const handleClickOption = (
    item: DocumentItemType,
    option: DocumentOptionType
  ) => {
    switch (option) {
      case DocumentOptionType.download:
        if (item.id) {
          const downloadFunc = onClickDownload ?? downloadFile;
          downloadFunc(item.id);
        }
        break;
      case DocumentOptionType.delete:
        if (item.id) {
          onClickDelete?.(item.id);
        }
        break;
      default:
        break;
    }
  };

  const handleClickName = (item: DocumentItemType) => {
    if (onClickName) {
      onClickName(item);
    } else if (item.id) {
      previewFile(item.id);
    }
  };

  const columns = useColumns({
    editable,
    onClickName: handleClickName,
    onClickOption: handleClickOption,
  });

  const rows = useMemo(
    () =>
      lodash
        .sortBy(files, (v) => v.createdAt)
        .map((v, i) => ({
          number: i + 1,
          ...v,
          createdAt: convertDateToUserTimezone(v.createdAt),
        })),
    [convertDateToUserTimezone, files]
  );

  return (
    <Table
      hideFooter
      columns={columns}
      isError={isError}
      noRowsLabel={noRowsLabel ?? t('client.client.documents.noRowsLabel')}
      overlayWrapperHeight={56}
      rows={rows}
    />
  );
};
