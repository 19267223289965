import { FC } from 'react';

import { CountryEntity, KycEntity } from 'api/types/entity';
import { Stack } from 'libs/ui';
import {
  PersonalDataSection,
  CitizenshipSection,
  ContactsSection,
  PepConnectionSection,
  TaxResidencySection,
  RegistrationAddressSection,
  ResidentialAddressSection,
  ActivitySection,
  IdentityDocumentSection,
  DocumentsSection,
  AdditionalDocumentsSection,
  KYCSection,
  LegalClientHeadPositionSection,
} from 'modules/client/common/components/sections';

export interface LegalClientFirstHeadSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  kycData?: KycEntity;
  hideActivitySection?: boolean;
}

export const LegalClientFirstHeadSections: FC<
  LegalClientFirstHeadSectionsProps
> = ({ countries, countriesLoading, kycData, hideActivitySection }) => (
  <Stack spacing={72}>
    {!hideActivitySection && <ActivitySection isBaseForm />}
    <ContactsSection hidePhoneField />
    <PersonalDataSection />
    <LegalClientHeadPositionSection onlyPosition />
    <CitizenshipSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <TaxResidencySection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <IdentityDocumentSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <KYCSection kycData={kycData} />
    <RegistrationAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <ResidentialAddressSection
      enabledFiles
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <PepConnectionSection />
    <DocumentsSection />
    <AdditionalDocumentsSection />
  </Stack>
);
