import { useMemo } from 'react';

import { ErrorLayout, LoadingLayout } from 'components';
import { filterVisibleOnboardingStatuses } from 'modules/client/common/helpers/filterVisibleOnboardingStatuses';

import { useLegalClientVersion } from '../hooks/useLegalClientVersion';
import { LegalClientVersionView } from '../views/LegalClientVersionView';

export const LegalClientVersionContainer = () => {
  const {
    initialValues,
    loading,
    error,
    versions,
    breadcrumbsTitles,
    fullName,
    snapshot,
    snapshotLoading,
    versionId,
    onChangeClientType,
    onChangeVersion,
    onCloseVersionHistory,
    setBeneficiaryRowHref,
    setBranchRowHref,
    setHeadRowHref,
    setLegalHeadRowHref,
  } = useLegalClientVersion();

  const visibleOnboardingStatuses = useMemo(
    () => filterVisibleOnboardingStatuses(snapshot?.value.onboarding?.statuses),
    [snapshot?.value.onboarding?.statuses]
  );

  if (error) {
    return <ErrorLayout />;
  }

  if (loading) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientVersionView
      hideActivitySection
      beneficiary={{
        unidentifiableBeneficiaryLetterFiles:
          snapshot?.value.unidentifiableBeneficiaryLetterFiles,
        beneficiaries: snapshot?.value.beneficiaries ?? [],
      }}
      bodies={snapshot?.value.bodies ?? []}
      branches={snapshot?.value.branches ?? []}
      breadcrumbsTitles={breadcrumbsTitles}
      countries={[]}
      currentAccountStatus={snapshot?.value.accountStatus}
      currentOnboardingStatus={snapshot?.value.onboardingStatus}
      fullName={fullName}
      heads={snapshot?.value.heads ?? []}
      initialValues={initialValues}
      legalHeads={snapshot?.value.legalHeads ?? []}
      managers={[]}
      setBeneficiaryRowHref={setBeneficiaryRowHref}
      setBranchRowHref={setBranchRowHref}
      setHeadRowHref={setHeadRowHref}
      setLegalHeadRowHref={setLegalHeadRowHref}
      snapshotLoading={snapshotLoading}
      versionId={versionId}
      versions={versions ?? []}
      visibleOnboardingStatuses={visibleOnboardingStatuses}
      onChangeClientType={onChangeClientType}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  );
};
