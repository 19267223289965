import { BaseRepresentativeClientEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';
import {
  parseBiographyInitialValue,
  parsePepConnectionInitialValue,
  parseBaseDebtsInitialValue,
  parseIdentityDocumentInitialValue,
  parseAddressValue,
  parseResidentialAddressValue,
} from 'modules/client/common/helpers';

import { RepresentativeFormType } from '../../forms/representativeForm';

import { parseBasisForRepresentation } from './parseBasisForRepresentation';
import { parseDocumentsFiles } from './parseDocumentsFiles';

export const getBaseRepresentativeInitialValue = (
  client: BaseRepresentativeClientEntity,
  t: TFunc
): RepresentativeFormType => ({
  reasonChange: { reason: '' },
  contacts: {
    email: client.email,
    phone: client.phone,
  },

  personalData: {
    firstName: client.firstName,
    lastName: client.lastName,
    middleName: client.middleName ?? '',
    dateOfBirth: client.dateOfBirth,
  },

  citizenships: client.citizenships ?? [null],

  taxResidences: client.taxResidences?.map((v) => ({
    country: v.country || null,
    reasonForAbsence: v.reasonForMissingTaxId ?? '',
    taxNumber: v.taxId ?? '',
    taxNumberIsMissing: !v.taxId && !!v.reasonForMissingTaxId,
  })) || [{ country: null }],

  registrationAddress: parseAddressValue(client.registrationAddress),
  residentialAddress: parseResidentialAddressValue(client.residentialAddress),

  placeOfBirth: {
    country: client.placeOfBirth?.country ?? null,
    locality: client.placeOfBirth?.locality ?? '',
  },

  biography: parseBiographyInitialValue(t, client.biography),

  bankAccounts: client.bankAccounts?.map((v) => ({
    country: v.country || null,
    swiftCode: v.swiftCode,
  })) || [{ country: null, swiftCode: '' }],

  pepConnection: parsePepConnectionInitialValue(client.pepConnection, t),

  debts: parseBaseDebtsInitialValue(client.debts, t),

  activity: {},

  basisForRepresentation: parseBasisForRepresentation(
    client.basisForRepresentation
  ),
  additionalDocumentsFiles: client.additionalInformationFiles,

  documentsFiles: parseDocumentsFiles(client),
  identityDocument: parseIdentityDocumentInitialValue(
    t,
    client.identityDocument
  ),
});
