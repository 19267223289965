import { FC } from 'react';

import { CountryEntity } from 'api/types/entity';
import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { useFormErrorFocus } from 'hooks';
import { Stack } from 'libs/ui';
import {
  CitizenshipSection,
  PepConnectionSection,
  TaxResidencySection,
  ReasonAddSection,
  RegistrationAndResidentialAddressSection,
  LegalClientAddHeadPositionSection,
} from 'modules/client/common/components/sections';

import { PersonalDataSection } from '../sections';

export interface LegalClientAddHeadSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  bodies: LegalClientBodyEntity[];
}

export const LegalClientAddHeadSections: FC<
  LegalClientAddHeadSectionsProps
> = ({ countries, countriesLoading, bodies }) => {
  useFormErrorFocus();

  return (
    <Stack spacing={48}>
      <ReasonAddSection />
      <PersonalDataSection />
      <LegalClientAddHeadPositionSection bodies={bodies} />
      <CitizenshipSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <TaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <RegistrationAndResidentialAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <PepConnectionSection />
    </Stack>
  );
};
