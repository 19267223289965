import isPropValid from '@emotion/is-prop-valid';

import { styled } from '../styled';

/**
 * Pressed component.
 */
export const Pressed = styled('button', {
  shouldForwardProp: (prop) => isPropValid(prop.toString()) || prop === 'datax',
})(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-flex',

  '&, & > *': {
    transition: theme.transitions.create(['color', 'background-color']),
  },
}));
