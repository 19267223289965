import { PropsWithChildren } from 'react';

import { FormSubmit } from 'libs/form';
import { useTranslation } from 'libs/i18n';

import {
  Form,
  SystemSettingsFormType,
  getSchema,
  FormErrors,
} from '../../forms/systemSettingsForm';

export interface SystemSettingsFormProps {
  initialValues?: SystemSettingsFormType;
  initialErrors?: FormErrors;
  onSubmit: FormSubmit<SystemSettingsFormType>;
}

export const SystemSettingsForm = ({
  initialValues,
  initialErrors,
  children,
  onSubmit,
}: SystemSettingsFormProps & PropsWithChildren) => {
  const { t } = useTranslation();
  const schema = getSchema(t);

  return (
    <Form
      initialReadOnly
      initialErrors={initialErrors}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {children}
    </Form>
  );
};
