import { Loading } from '../Loading';

import { BackdropStyled } from './LoadingBackdrop.styles';

export interface LoadingBackdropProps {
  isOpen: boolean;
  onClickBackdrop?: () => void;
}

export const LoadingBackdrop = ({
  isOpen,
  onClickBackdrop,
}: LoadingBackdropProps) => (
  <BackdropStyled open={isOpen} onClick={onClickBackdrop}>
    <Loading />
  </BackdropStyled>
);
