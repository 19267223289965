import { FC } from 'react';

import { ManagerData, OnboardingDataStatus } from 'api/types/data';
import { CountryEntity } from 'api/types/entity';
import { Stack } from 'libs/ui';
import {
  CompanyNameSection,
  DebtsSection,
  WithdrawalAccountsSection,
  CompanyRegistrationAddressSection,
  CompanyResidentialAddressSection,
  CompanyStateRegistrationSection,
  AccountCodesSection,
  ActivitySection,
  OpenAccountOrderSection,
  DocumentsSection,
  AdditionalDocumentsSection,
  ServiceContractSection,
  RiskLevelSection,
  ResultCheckSection,
} from 'modules/client/common/components/sections';
import { LockAndUnlockReadOnlyProps } from 'modules/client/common/hooks';

import {
  CompanyTaxResidencySection,
  CompanyActivitiesSection,
  CompanyBankAccountsSection,
  CompanyBeneficiariesSection,
  CompanyBranchesSection,
  CompanyContactsSection,
  CompanyFinancingSourcesSection,
  CompanyHeadsSection,
  CompanyLicenseSection,
  CompanyOrganizationalAndLegalFormSection,
  CompanyRegistrationPlaceSection,
  CompanyStructureSection,
  CompanyFinancialMonitoringSection,
  CompanyOrderSubmissionContactsSection,
  CompanyBusinessRelationshipSection,
  GeneralSection,
  CompanyStructureSectionProps,
  CompanyBeneficiariesSectionProps,
  CompanyBranchesSectionProps,
  CompanyHeadsSectionProps,
} from '../sections';

export interface LegalClientSectionsProps
  extends LockAndUnlockReadOnlyProps,
    CompanyStructureSectionProps,
    CompanyBeneficiariesSectionProps,
    CompanyBranchesSectionProps,
    CompanyHeadsSectionProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  hideActivitySection?: boolean;
  managers: ManagerData[];
  managersLoading?: boolean;
  visibleOnboardingStatuses?: OnboardingDataStatus[];
  isEditablePersonalAccountNumber?: boolean;
}

export const LegalClientSections: FC<LegalClientSectionsProps> = ({
  countries,
  countriesLoading,
  heads,
  legalHeads,
  beneficiary,
  branches,
  managers,
  managersLoading,
  visibleOnboardingStatuses,
  hideActivitySection,
  bodies,
  isEditablePersonalAccountNumber,
  onClickHead,
  onClickLegalHead,
  onClickAddHead,
  onClickAddBeneficiary,
  onClickAddLegalHead,
  onClickAddBranch,
  onClickBeneficiary,
  onClickBranch,
  onDeleteBeneficiary,
  onDeleteHead,
  onDeleteLegalHead,
  onAddBody,
  onUpdateBody,
  onArchiveBody,
  onDearchiveBody,
  onDeleteBody,
  onArchiveHead,
  onArchiveLegalHead,
  onDearchiveHead,
  onDearchiveLegalHead,
  onArchiveBeneficiary,
  onDearchiveBeneficiary,
  onDearchiveBranch,
  onArchiveBranch,
  onDeleteBranch,
  setHeadRowHref,
  setLegalHeadRowHref,
  setBeneficiaryRowHref,
  setBranchRowHref,
}) => (
  <Stack spacing={72}>
    <ResultCheckSection hideKyc statuses={visibleOnboardingStatuses} />
    <GeneralSection />
    <OpenAccountOrderSection />
    <ServiceContractSection />
    <RiskLevelSection />
    {!hideActivitySection && <ActivitySection isBaseForm />}
    <AccountCodesSection
      isEditablePersonalAccountNumber={isEditablePersonalAccountNumber}
      managers={managers}
      managersLoading={managersLoading}
    />
    <CompanyNameSection showEn />
    <CompanyTaxResidencySection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyRegistrationPlaceSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyRegistrationAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyResidentialAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyStateRegistrationSection />
    <CompanyOrganizationalAndLegalFormSection />
    <CompanyActivitiesSection />

    <CompanyLicenseSection />

    <CompanyStructureSection
      bodies={bodies}
      onAddBody={onAddBody}
      onArchiveBody={onArchiveBody}
      onDearchiveBody={onDearchiveBody}
      onDeleteBody={onDeleteBody}
      onUpdateBody={onUpdateBody}
    />

    <CompanyHeadsSection
      heads={heads}
      legalHeads={legalHeads}
      setHeadRowHref={setHeadRowHref}
      setLegalHeadRowHref={setLegalHeadRowHref}
      onArchiveHead={onArchiveHead}
      onArchiveLegalHead={onArchiveLegalHead}
      onClickAddHead={onClickAddHead}
      onClickAddLegalHead={onClickAddLegalHead}
      onClickHead={onClickHead}
      onClickLegalHead={onClickLegalHead}
      onDearchiveHead={onDearchiveHead}
      onDearchiveLegalHead={onDearchiveLegalHead}
      onDeleteHead={onDeleteHead}
      onDeleteLegalHead={onDeleteLegalHead}
    />
    <CompanyBeneficiariesSection
      beneficiary={beneficiary}
      setBeneficiaryRowHref={setBeneficiaryRowHref}
      onArchiveBeneficiary={onArchiveBeneficiary}
      onClickAddBeneficiary={onClickAddBeneficiary}
      onClickBeneficiary={onClickBeneficiary}
      onDearchiveBeneficiary={onDearchiveBeneficiary}
      onDeleteBeneficiary={onDeleteBeneficiary}
    />

    <CompanyContactsSection />

    <CompanyBranchesSection
      branches={branches}
      setBranchRowHref={setBranchRowHref}
      onArchiveBranch={onArchiveBranch}
      onClickAddBranch={onClickAddBranch}
      onClickBranch={onClickBranch}
      onDearchiveBranch={onDearchiveBranch}
      onDeleteBranch={onDeleteBranch}
    />

    <CompanyBankAccountsSection
      countries={countries}
      countriesLoading={countriesLoading}
    />
    <CompanyFinancingSourcesSection />

    <DebtsSection isEntrepreneur />

    <CompanyFinancialMonitoringSection hasBranches={!!branches.length} />

    <CompanyBusinessRelationshipSection />

    <WithdrawalAccountsSection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyOrderSubmissionContactsSection />

    <DocumentsSection />
    <AdditionalDocumentsSection />
  </Stack>
);
