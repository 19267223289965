// eslint-disable-next-line no-restricted-imports
import { BaseLegalClientRepresentativeFragment } from 'api/generated/graphql';
import {
  parseCountryEntity,
  parseOboardingData,
  parsePlaceOfBirth,
} from 'api/helpers';
import { parseBiographyEntity } from 'api/helpers/client/parseEntity/parseBiographyEntity';
import { parseTaxResidenceEntity } from 'api/helpers/client/parseEntity/parseTaxResidenceEntity';
import {
  parsePepConnectionEntity,
  PepConnectionTypeApi,
} from 'api/helpers/parseEntity/parsePepConnectionEntity';
import { LegalClientRepresentativeEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileDocumentEntity } from '../client/parseEntity/parseFileEntity';
import { parseIdentityDocumentEntity } from '../parseEntity/parseIdentityDocumentEntity';
import { parseKycEntity } from '../parseEntity/parseKycEntity';
import { parseResidentialAddressEntity } from '../parseEntity/parseResidentialAddressEntity';

import { parseCompanyEntity } from './parseEntity/parseCompanyEntity';
import { parseRoleInCompanyEntity } from './parseEntity/parseRoleInCompanyEntity';

export const parseBaseLegalClientRepresentative = (
  data: Omit<
    BaseLegalClientRepresentativeFragment,
    '__typename' | 'pepConnectionType'
  > & { pepConnectionType?: PepConnectionTypeApi | null }
): LegalClientRepresentativeEntity => ({
  id: data.id,
  versionNumber: data.version?.number,
  versionId: data.version?.id,
  legalClientId: data.legalClientId || undefined,
  onboarding: parseOboardingData(data.onboarding),
  roleInCompany: data.roleInCompany
    ? parseRoleInCompanyEntity(data.roleInCompany)
    : undefined,
  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  registrationAddress: data.registrationAddress,
  residentialAddress: parseResidentialAddressEntity(data.residentialAddress),
  placeOfBirth: data.placeOfBirth
    ? parsePlaceOfBirth(data.placeOfBirth)
    : undefined,
  biography: data.biography ? parseBiographyEntity(data.biography) : undefined,
  pepConnection: parsePepConnectionEntity(data),

  company: parseCompanyEntity(data),

  lastName: data.lastName,
  dateOfBirth: new Date(data.dateOfBirth),
  email: data.email,
  firstName: data.firstName,
  middleName: data.middleName ?? undefined,
  phone: data.phoneNumber ?? undefined,

  additionalInformationFiles: lodash
    .compact(data.additionalInformationDocuments)
    .map(parseFileDocumentEntity),
  additionalDocumentFiles: data.additionalDocuments
    ? lodash.compact(data.additionalDocuments).map(parseFileDocumentEntity)
    : undefined,

  kyc: parseKycEntity(data.kyc),

  selfieFiles: data.selfieDocuments
    ? lodash.compact(data.selfieDocuments).map(parseFileDocumentEntity)
    : undefined,

  identityDocument: data.identityDocument
    ? parseIdentityDocumentEntity(data.identityDocument)
    : undefined,
});
