import { CompanyBaseStateRegistrationFormType } from 'modules/client/common/forms/companyStateRegistrationForm';
import { parseAddressValue } from 'modules/client/common/helpers';

import { LegalClientBranchFormType } from '../../forms/legalClientBranchForm';

export const getLegalClientBranchInitialValue =
  (): LegalClientBranchFormType => ({
    reasonChange: { reason: '' },

    contacts: {
      email: '',
      phone: '',
    },

    companyTaxResidences: [{ country: null, taxNumber: '' }],

    companyRegistrationAddress: parseAddressValue(),
    companyResidentialAddress: parseAddressValue(),

    companyName: { fullName: '' },

    companyStateRegistration:
      {} as CompanyBaseStateRegistrationFormType['companyStateRegistration'],
  });
