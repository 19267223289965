import { useDialog } from 'libs/ui/Dialog/useDialog';

export const useAddDialogs = (onSuccessAdd: () => void) => {
  const { open: openAddHeadDialog, ...dialogAddHeadDialogProps } = useDialog();
  const { open: openAddBeneficiaryDialog, ...dialogAddBeneficiaryDialogProps } =
    useDialog();
  const { open: openAddLegalHeadDialog, ...dialogAddLegalHeadDialogProps } =
    useDialog();
  const { open: openAddBranchDialog, ...dialogAddBranchDialogProps } =
    useDialog();

  const onClickAddHead = () => {
    openAddHeadDialog();
  };
  const onClickAddBeneficiary = () => {
    openAddBeneficiaryDialog();
  };
  const onClickAddLegalHead = () => {
    openAddLegalHeadDialog();
  };
  const onClickAddBranch = () => {
    openAddBranchDialog();
  };

  const closureOnSuccess = (onClose: () => void) => () => {
    onClose();
    onSuccessAdd();
  };

  const onSuccessAddHead = closureOnSuccess(dialogAddHeadDialogProps.onClose);
  const onSuccessAddBeneficiary = closureOnSuccess(
    dialogAddBeneficiaryDialogProps.onClose
  );
  const onSuccessAddLegalHead = closureOnSuccess(
    dialogAddLegalHeadDialogProps.onClose
  );
  const onSuccessAddBranch = closureOnSuccess(
    dialogAddBranchDialogProps.onClose
  );

  return {
    dialogAddHeadDialogProps,
    dialogAddBeneficiaryDialogProps,
    dialogAddLegalHeadDialogProps,
    dialogAddBranchDialogProps,
    onClickAddHead,
    onClickAddBeneficiary,
    onClickAddLegalHead,
    onClickAddBranch,
    onSuccessAddHead,
    onSuccessAddBeneficiary,
    onSuccessAddLegalHead,
    onSuccessAddBranch,
  };
};
