import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientBranchSnapshotQuery } from 'api/generated/graphql';
import { useError, parseLegalClientBranchSnapshotEntity } from 'api/helpers';

import { RequestOptions } from '../types';

export const useLegalClientBranchSnapshot = (
  branchId: string,
  versionId: string,
  options: RequestOptions = {}
) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientBranchSnapshotQuery({
    variables: { id: branchId, versionId },
    ...options,
  });

  const error = useError(apolloError, false);

  const snapshot = useMemo(
    () =>
      data?.legalClientBranchSnapshot
        ? parseLegalClientBranchSnapshotEntity(data.legalClientBranchSnapshot)
        : null,
    [data?.legalClientBranchSnapshot]
  );

  return {
    snapshot,
    loading,
    error,
    isError: !!apolloError,
    refetch,
  };
};
