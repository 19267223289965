import { FC } from 'react';

import { AdminStatusType } from 'api/types/entity';
import { ErrorLayout, LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks';

import { useAdmin } from '../hooks';
import { AdminView } from '../views';

export type AdminContainerProps = {};

export const AdminContainer: FC<AdminContainerProps> = () => {
  const {
    submitLoading,
    initialValues,
    loading,
    error,
    formError,
    breadcrumbsTitles,
    admin,
    timerData,
    revokeOtpVerificationLoading,
    onSubmit,
    onResetPassword,
    onResendInvite,
    onActivate,
    onDeactivate,
    onRevokeOtpVerification,
  } = useAdmin();

  useScrollToTop([]);

  if (error || (!initialValues && !loading)) {
    return <ErrorLayout />;
  }

  if (loading || !initialValues) {
    return <LoadingLayout />;
  }

  return (
    <AdminView
      breadcrumbsTitles={breadcrumbsTitles}
      formError={formError}
      initialValues={initialValues}
      isActive={admin?.isActive}
      isDeactivated={admin?.status === AdminStatusType.Deactivated}
      resendTime={timerData?.resendTime}
      revokeOtpVerificationLoading={revokeOtpVerificationLoading}
      submitLoading={submitLoading}
      onActivate={onActivate}
      onDeactivate={onDeactivate}
      onResendInvite={onResendInvite}
      onResetPassword={onResetPassword}
      onRevokeOtpVerification={onRevokeOtpVerification}
      onSubmit={onSubmit}
    />
  );
};
