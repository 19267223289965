import { LoaderStyled } from './Loading.styles';

export interface LoadingProps {
  className?: string;
}

const Stick = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill="none"
    height="74"
    viewBox="0 0 21 74"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      height="74"
      id="mask0_9194_21915"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="21"
      x="0"
      y="0"
    >
      <path
        d="M12.238 67.1287C17.599 64.4928 20.9873 59.0921 20.9873 53.182L20.9873 6.10352e-05L9.46957 5.66456C3.67929 8.50962 0.0203757 14.3419 0.0203751 20.7264L0.0203705 73.1348L12.238 67.1287Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_9194_21915)">
      <path
        d="M-0.0298436 2.10597e-05L21.002 2.28882e-05L21.002 73.0723L-0.02985 73.0723L-0.0298436 2.10597e-05Z"
        fill="url(#pattern0_9194_21915)"
      />
    </g>
    <defs>
      <pattern
        height="1"
        id="pattern0_9194_21915"
        patternContentUnits="objectBoundingBox"
        width="1"
      >
        <use
          transform="scale(0.00598802 0.00340136)"
          xlinkHref="#image0_9194_21915"
        />
      </pattern>
      <image
        height="294"
        id="image0_9194_21915"
        width="167"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKcAAAEmCAIAAACI7xdyAAAABmJLR0QA/wD/AP+gvaeTAAADTklEQVR4nO3dwY0CQQwAQRs5/0gIjz9kwT66KoKTrPYMsHB7788QczPfp/8G/u326b+A/3s9/QfwAK0Xab3oVuw9NnyRDV+k9SKtF7nNFdnwRTZ8kdaLtF6k9aLb9fl6jg1fZMMXab1I60XekS2y4Yts+CKtF2m9yNSLboy9R+tFbnNFWi8y9aIbn6/3aL3Iba5I60WmXmTDF3mqokjrRc71IlMvsuGL3OaKtF7kXC8y9SIbvsj314u0XuRcLzL1Ihu+SOtFvvFUpPUiUy9ymyvSepHbXJHWi0y9yG2uSOtFWi/yVEWRDV9kwxdpvUjrRb7xVGTDF9nwRVov0nqR1ovc4Yts+CIbvkjrRVov8vl6kQ1fZMMXab1I60Xemyuy4Yts+CKtF2m9yNSL/GpBkdaL3OaKtF5k6kU3Pl/v0XqR21yR1otMvciGL/JURZHWi5zrRaZeZMMXuc0Vab3IuV5k6kU2fJHWizxVUaT1IlMvcpsr0nqRbzwVab3I1Ivc5oq0XqT1Ik9VFNnwRTZ8kdaLtF7kvwIU2fBFNnyR1ou0XuS9uSIbvsiGL9J6kdaLtF7kDl9kwxfZ8EVaL9J6kc/Xi2z4Ihu+SOtFWi/SepGpF3kfvkjrRW5zRVov0nqR1ou0XuRXBou0XuRcL9J6kdaLbscTVDnu8EXO9SLnepHWi7RepPUirRd55VZkwxfZ8EVaL9J6kdaLtF7kG09FWi9yrhfdeJamR+tFzvUid/girRc514u0XqT1Iq0Xab3Ic3NFWi9yrheZepENX6T1Iq0XeeVW5HdpipzrRc71Iq0Xab3IHb5I60XO9SKtF2m9SOtFWi/yjacirRc514u0XmTqRTZ8kdaLbtcTVDlaLzL1Ire5Iq0XmXqRDV+k9SJTLzL1Ik9VFGm9yNSLvHIr0nqRqRfZ8EVaLzL1IlMvcq4X+YWSIhu+yIYv0nqRqRfZ8EVaLzL1IlMvcq4XeW+uyIYvsuGLtF5k6kX+s1eR1otMvcjUi7xyK9J6kXdki2z4Ihu+yNSLbPgirReZepGpFznXi7Re5L25Ihu+yIYvMvUiG75I60WmXmTqRc71Iq0XmXqR//ZTpPUiUy9yhy/SepGpF5l6kXO9SOtFpl70A9RAFOVKXclkAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);

export const Loading = ({ className }: LoadingProps) => (
  <LoaderStyled className={className}>
    <Stick className="loading-left" />
    <Stick className="loading-right" />
  </LoaderStyled>
);
