import { useEffect } from 'react';

import { useField } from 'libs/form/fields/useField';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { Button, Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { DocumentsTable } from '../../components';
import { LocalFileEntity } from '../../types';

import { useDocumentField } from './hooks';

interface Props {
  readOnly?: boolean;
  title?: string;
  maxDocumentCount?: number;
  name: string;
}

type FileType = LocalFileEntity & { loading?: boolean } & { id: string };

export const DocumentsTableFeature = ({
  readOnly,
  title,
  maxDocumentCount,
  name,
}: Props) => {
  const { t } = useTranslation();

  const { field, inputProps, meta, helpers, validateField } =
    useField<FileType[]>(name);

  const { readOnly: contextReadOnly } = useFormContext();

  const { open, getInputProps, onClickDelete } = useDocumentField({
    name,
    maxDocumentCount,
  });

  const files = field.value ?? [];

  const editable = !(readOnly ?? contextReadOnly);

  const shownHead = !!title || editable;
  const disabledUpload =
    files.length > 0 && files.length >= (maxDocumentCount ?? Infinity);

  useEffect(() => {
    if (files.length) {
      validateField();
    }
  }, [files.length, helpers, validateField]);

  return (
    <Stack spacing={32}>
      <input {...getInputProps()} aria-invalid={inputProps.error} />

      {shownHead && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={16}
        >
          <Typography variant="headline">{title}</Typography>
          {editable && (
            <Button
              disabled={disabledUpload}
              label={t('common.uploadDocument')}
              mode="medium"
              startIcon={<Icon.Plus />}
              onClick={open}
            />
          )}
        </Stack>
      )}
      <DocumentsTable
        editable={editable}
        files={files}
        isError={meta.isError && !files.length}
        noRowsLabel={meta.isError ? t('validation.files') : undefined}
        onClickDelete={onClickDelete}
      />
    </Stack>
  );
};
