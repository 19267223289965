import { CreateLegalClientLegalHeadParams } from 'api/types/params';
import { formatToISODate, yesOrNot } from 'helpers';
import {
  parseCompanyAddressParam,
  parseCompanyTaxResidencyParam,
  parseStateRegistrationParam,
} from 'modules/client/common/helpers';

import { LegalClientLegalHeadFormType } from '../../forms/legalClientLegalHeadForm';

export const getLegalClientLegalHeadSubmitData = (
  legalClientId: string,
  versionNumber: number,
  values: LegalClientLegalHeadFormType
): CreateLegalClientLegalHeadParams => {
  const addressParam = parseCompanyAddressParam(values);

  return {
    versionNumber,
    legalClientId,
    reason: values.reasonChange.reason,
    fullName: values.companyName.fullName,
    shortName: values.companyName.shortName,
    legalAddress: addressParam.legalAddress,
    actualAddress: addressParam.actualAddress,
    taxResidences: parseCompanyTaxResidencyParam(values),

    stateRegistration: parseStateRegistrationParam({
      companyStateRegistration: values.companyStateRegistration!,
    }),
    position: {
      positionInCompany: values.position.position,
      isFirstHead: yesOrNot(values.position.firstHead?.value) ?? false,
      legalClientBodyId: values.position.body?.value!,
      expirationDate: values.position.dateOfExpiration
        ? formatToISODate(values.position.dateOfExpiration)
        : undefined,
      positionConfirmationFileIds:
        values.position.positionConfirmationFiles.map(({ id }) => id!),
    },
  };
};
