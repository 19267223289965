import { TFunc } from 'libs/i18n';
import {
  parseAddressValue,
  parseTaxResidencesValue,
  parsePepConnectionInitialValue,
} from 'modules/client/common/helpers';

import { LegalClientFirstHeadFormType } from '../../forms/legalClientFirstHeadForm';
import { BaseLegalClientHeadPositionFormType } from '../../forms/positionInCompanyForm';

export const getLegalClientFirstHeadInitialValue = (
  t: TFunc
): LegalClientFirstHeadFormType => ({
  reasonChange: { reason: '' },

  personalData: {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: undefined,
  },

  citizenships: [null],

  taxResidences: parseTaxResidencesValue(),

  registrationAddress: parseAddressValue(),
  residentialAddress: parseAddressValue(),

  pepConnection: parsePepConnectionInitialValue(undefined, t),

  position: {
    headType: null,
    position: '',
    positionConfirmationFiles: [],
    body: null,
  } as BaseLegalClientHeadPositionFormType['position'],
});
