import { PermissionType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { usePermissions } from 'libs/permissions';
import { Table, TableSetRowHref } from 'libs/ui';

import { useColumns } from './columns';
import { BeneficiaryItem, BeneficiaryOptionType } from './types';

interface Props {
  editable?: boolean;
  items: BeneficiaryItem[];
  onCellClick?: (row: BeneficiaryItem) => void;
  onClickDelete?: (row: BeneficiaryItem) => void;
  onClickArchive?: (row: BeneficiaryItem) => void;
  setRowHref?: TableSetRowHref<BeneficiaryItem>;
}

export const BeneficiariesTable = ({
  items,
  editable,
  onCellClick,
  onClickDelete,
  onClickArchive,
  setRowHref,
}: Props) => {
  const onClickOption = (
    item: BeneficiaryItem,
    option: BeneficiaryOptionType
  ) => {
    switch (option) {
      case BeneficiaryOptionType.delete:
        if (item.id) {
          onClickDelete?.(item);
        }
        break;
      case BeneficiaryOptionType.archive:
      case BeneficiaryOptionType.dearchive:
        if (item.id) {
          onClickArchive?.(item);
        }
        break;
      default:
        break;
    }
  };

  const { checkPermissions } = usePermissions();

  const shownOptions: Partial<Record<BeneficiaryOptionType, boolean>> = {
    archive: true,
    dearchive: true,
    delete: checkPermissions([PermissionType.LegalClientBeneficiaryDeleteOne]),
  };

  const columns = useColumns({ editable, shownOptions, onClickOption });

  const { t } = useTranslation();

  return (
    <Table
      hideFooter
      columns={columns}
      noRowsLabel={t('client.legal.companyBeneficiaries.noRowsLabel')}
      rows={items}
      setRowHref={setRowHref}
      onCellClick={onCellClick}
    />
  );
};
