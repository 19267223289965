import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';

import { CitizenshipFormType, citizenshipsSchema } from './citizenshipForm';
import {
  PepConnectionFormType,
  getPepConnectionSchema,
} from './pepConnectionForm';
import {
  PersonalDataFormType,
  getPersonalDataSchema,
} from './personalDataForm';
import {
  LegalClientHeadPositionFormType,
  getLegalClientHeadPositionFormSchema,
} from './positionInCompanyForm';
import {
  RegistrationAddressFormType,
  registrationAddressSchema,
} from './registrationAddressForm';
import {
  ResidentialAddressFormType,
  residentialAddressSchema,
} from './residentialAddressForm';
import { TaxResidencyFormType, taxResidencesSchema } from './taxResidencyForm';

export interface LegalClientHeadFormType
  extends LegalClientHeadPositionFormType,
    PersonalDataFormType,
    CitizenshipFormType,
    TaxResidencyFormType,
    RegistrationAddressFormType,
    ResidentialAddressFormType,
    PepConnectionFormType,
    ReasonChangeFormType {}

export const getSchema = (t: TFunc): ObjectSchema<LegalClientHeadFormType> =>
  object()
    .concat(getPersonalDataSchema(t))
    .concat(citizenshipsSchema)
    .concat(taxResidencesSchema)
    .concat(registrationAddressSchema)
    .concat(residentialAddressSchema)
    .concat(getLegalClientHeadPositionFormSchema(t))
    .concat(getPepConnectionSchema(t))
    .concat(reasonChangeSchema);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalClientHeadFormType>();

export type FormErrors = FormErrorsBase<LegalClientHeadFormType>;
