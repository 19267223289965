import { FC } from 'react';

import { CountryEntity } from 'api/types/entity';
import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { useFormErrorFocus } from 'hooks';
import { Stack } from 'libs/ui';
import {
  ReasonAddSection,
  CompanyNameSection,
  CompanyStateRegistrationSection,
  CompanyRegistrationAndResidentialAddressSection,
  LegalClientAddHeadPositionSection,
} from 'modules/client/common/components/sections';
import { CompanyTaxResidencySection } from 'modules/client/legal/components/sections';

export interface LegalClientAddLegalHeadSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  bodies: LegalClientBodyEntity[];
}

export const LegalClientAddLegalHeadSections: FC<
  LegalClientAddLegalHeadSectionsProps
> = ({ countries, countriesLoading, bodies }) => {
  useFormErrorFocus();

  return (
    <Stack spacing={48}>
      <ReasonAddSection />

      <CompanyNameSection />

      <LegalClientAddHeadPositionSection bodies={bodies} />

      <CompanyTaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <CompanyRegistrationAndResidentialAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <CompanyStateRegistrationSection />
    </Stack>
  );
};
