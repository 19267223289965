import { useCreateLegalClientBranch } from 'api/requests';
import { useCountries } from 'api/requests/country';
import { LegalClientEntity, LegalClientBranchEntity } from 'api/types/entity';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import {
  getSchema,
  LegalClientBranchFormType,
} from '../forms/legalClientBranchForm';
import {
  getLegalClientBranchInitialValue,
  getLegalClientBranchSubmitData,
} from '../helpers';

export const useLegalClientAddBranch = (
  legalClient: LegalClientEntity,
  onSuccess: (legalClientBranch: LegalClientBranchEntity) => void
) => {
  const { countries, loading: countriesLoading } = useCountries();
  const { t } = useTranslation();
  const notify = useNotify();

  const { loading: submitLoading, createLegalClientBranch } =
    useCreateLegalClientBranch();

  const initialValues = getLegalClientBranchInitialValue();

  const validationSchema = getSchema(t);

  const onSubmit: FormSubmit<LegalClientBranchFormType> = async (values) => {
    const params = getLegalClientBranchSubmitData(
      legalClient.id,
      legalClient.versionNumber!,
      values
    );

    const branch = await createLegalClientBranch(params);
    if (branch) {
      onSuccess(branch);
      notify.info(t('client.legal.legalClientBranch.addDialog.successAdd'));
    }
  };

  return {
    countries: countries ?? [],
    countriesLoading,
    initialValues,
    validationSchema,
    submitLoading,
    onSubmit,
  };
};
