// eslint-disable-next-line no-restricted-imports
import {
  CreateLegalClientLegalHeadFirstHeadByAdminDto_Input,
  MutationInput_LegalClientLegalHeadFirstHeadController_Create_Input_PepConnectionType as PepConnectionTypeApi,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { CreateLegalClientFirstHeadParams } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseHeadPositionInCompanyParam } from '../parseParam';

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi.Self,
};

export const parseCreateLegalClientFirstHeadParams = (
  params: CreateLegalClientFirstHeadParams
): CreateLegalClientLegalHeadFirstHeadByAdminDto_Input => ({
  versionNumber: params.versionNumber,
  reason: params.reason,
  legalClientLegalHeadId: params.legalClientLegalHeadId,
  email: params.email,
  firstName: params.firstName,
  lastName: params.lastName,
  middleName: params.middleName,
  dateOfBirth: formatToISODate(params.dateOfBirth),
  citizenships: params.citizenships,

  registrationAddress: params.registrationAddress,
  residentialAddress: params.residentialAddress,
  taxResidences: params.taxResidences,
  connectedPepInfo: params.connectedPepInfo,
  pepConnectionType: relationType[params.pepConnection],
  positionInCompany: parseHeadPositionInCompanyParam(params.positionInCompany)!,
});
