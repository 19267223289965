import { FormikValues } from 'formik';
import { lodash } from 'helpers';

const parseStringValue = <T>(value: T) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  return value;
};

/**
 * Recursively prepare values.
 */
export function prepareDataForValidation<T extends FormikValues>(
  values: T
): FormikValues {
  const data: FormikValues = Array.isArray(values) ? [] : {};
  for (const k in values) {
    if (Object.prototype.hasOwnProperty.call(values, k)) {
      const key = String(k);
      if (Array.isArray(values[key]) === true) {
        data[key] = values[key].map((value: any) => {
          if (Array.isArray(value) === true || lodash.isPlainObject(value)) {
            return prepareDataForValidation(value);
          }
          const newValue = parseStringValue(value);
          return newValue !== '' ? value : null;
        });
      } else if (lodash.isPlainObject(values[key])) {
        data[key] = prepareDataForValidation(values[key]);
      } else {
        const value = parseStringValue(values[key]);

        data[key] = value !== '' ? value : null;
      }
    }
  }
  return data;
}
