import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';

import { Field } from '../../../forms/systemSettingsForm';

export const AccountSystemSettings = () => {
  const { t } = useTranslation();
  return (
    <Stack as="section" spacing={32}>
      <header>
        <Typography as="h3" variant="headline">
          {t('systemSettings.accountSystem.title')}
        </Typography>
      </header>

      <Typography as="h4" variant="subtitle">
        {t('systemSettings.accountSystem.authorizationAndSession.title')}
      </Typography>

      <Field.Number
        format={(v) => (!v ? '' : Number(v))}
        label={t(
          'systemSettings.accountSystem.authorizationAndSession.sessionLengthInDaysField.label'
        )}
        name="sessionLengthInDays"
        placeholder={t(
          'systemSettings.accountSystem.authorizationAndSession.sessionLengthInDaysField.placeholder'
        )}
      />
    </Stack>
  );
};
