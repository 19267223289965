import {
  TypedForm,
  ObjectSchema,
  string,
  object,
  mixed,
  array,
  date,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

import { getTodayWithResetHours, localFileScheme } from '../helpers';
import { LocalFileEntity } from '../types';

export enum LegalClientHeadType {
  person = 'person',
  legal = 'legal',
}

export interface BaseLegalClientHeadPositionFormType {
  position: {
    headType: Maybe<SelectType<LegalClientHeadType>>;
    position: string;
    dateOfExpiration?: Maybe<Date>;
    positionConfirmationFiles: LocalFileEntity[];
  };
}

export interface LegalClientHeadPositionFormType {
  position: {
    headType: Maybe<SelectType<LegalClientHeadType>>;
    body?: Maybe<SelectType<string>>;
    firstHead?: Maybe<YesOrNoSelectType>;
    position: string;
    dateOfExpiration?: Maybe<Date>;
    positionConfirmationFiles: LocalFileEntity[];
  };
}

export const maxLengthPosition = 200;

export const getLegalClientHeadTypeOptions = (t: TFunc) =>
  Object.values(LegalClientHeadType).map((v) => ({
    label: t(`client.legal.legalClientHead.position.headType.${v}`),
    value: v,
  }));

export const isLegal = (v: Maybe<SelectType<LegalClientHeadType>>) =>
  v?.value === LegalClientHeadType.legal;

export const isPerson = (v: Maybe<SelectType<LegalClientHeadType>>) =>
  v?.value === LegalClientHeadType.person;

export const getLegalClientHeadPositionFormSchema = (
  t: TFunc
): ObjectSchema<LegalClientHeadPositionFormType> =>
  object({
    position: object({
      headType: object({
        value: mixed<LegalClientHeadType>()
          .oneOf(Object.values(LegalClientHeadType))
          .required(),
        label: string().required(),
      })
        .default(null)
        .nullable(),

      body: object({
        value: string().required(),
        label: string().required(),
      }).required(),

      firstHead: object({
        value: mixed<YesOrNoType>()
          .oneOf(Object.values(YesOrNoType))
          .required(),
        label: string().required(),
      })
        .default(null)
        .required(),

      position: string().max(maxLengthPosition).required(),

      dateOfExpiration: date()
        .min(getTodayWithResetHours(), t('validation.minDate'))
        .nullable(),

      positionConfirmationFiles: array().of(localFileScheme).required().min(1),
    }),
  });

export const getBaseLegalClientHeadPositionFormSchema = (
  t: TFunc
): ObjectSchema<BaseLegalClientHeadPositionFormType> =>
  object({
    position: object({
      headType: object({
        value: mixed<LegalClientHeadType>()
          .oneOf(Object.values(LegalClientHeadType))
          .required(),
        label: string().required(),
      })
        .default(null)
        .nullable(),

      position: string().max(maxLengthPosition).required(),

      dateOfExpiration: date()
        .min(getTodayWithResetHours(), t('validation.minDate'))
        .nullable(),

      positionConfirmationFiles: array().of(localFileScheme).required().min(1),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalClientHeadPositionFormType>();
