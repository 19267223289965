import {
  useDeleteLegalClientBranch,
  useLegalClient,
  useLegalClientBranch as useLegalClientBranchApi,
  useLegalClientBranchArchive,
  useLegalClientBranchDearchive,
} from 'api/requests';
import { useCountries } from 'api/requests/country';
import { PermissionType } from 'api/types/entity';
import { NavigationBreadcrumbsTitles } from 'components';
import { useServerErrorNotify } from 'hooks';
import { FormSubmit } from 'libs/form/Form';
import { useTranslation } from 'libs/i18n';
import { useNavigate, RoutesId, ROUTES } from 'libs/navigation';
import { useNotify } from 'libs/notify';
import { usePermissions } from 'libs/permissions';

import { LegalClientBranchFormType } from '../forms/legalClientBranchForm';
import { getLegalClientBranchInitialValue } from '../helpers';

import { useBranchPageTitle } from './useBranchPageTitle';
import { useLockLegalClientBranch } from './useLockLegalClientBranch';
import { useUpdateLegalClientBranch } from './useUpdateLegalClientBranch';

type NavigateParams = {
  legalClientId: string;
  branchId: string;
};

export const useLegalClientBranch = () => {
  const notify = useNotify();
  const { t } = useTranslation();
  const { checkPermissions } = usePermissions();
  const { navigate, params } = useNavigate<NavigateParams>();

  const { loading, legalClientBranch, error } = useLegalClientBranchApi(
    params.branchId!
  );

  const { legalClient } = useLegalClient(params.legalClientId!);

  const { loading: submitLoading, updateLegalClientBranch } =
    useUpdateLegalClientBranch(legalClient);

  const { countries, loading: countriesLoading } = useCountries();

  const { lockClientIfPossible, unlockClient } = useLockLegalClientBranch(
    legalClient,
    legalClientBranch
  );

  const {
    deleteLegalClientBranch,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteLegalClientBranch();

  const {
    loading: archiveLoading,
    archive,
    error: archiveError,
  } = useLegalClientBranchArchive();
  const {
    loading: dearchiveLoading,
    dearchive,
    error: dearchiveError,
  } = useLegalClientBranchDearchive();

  const initialValues = legalClientBranch
    ? getLegalClientBranchInitialValue(legalClientBranch)
    : undefined;

  const breadcrumbsTitles: NavigationBreadcrumbsTitles = {
    [RoutesId.legalClientBranch]: legalClientBranch?.fullName ?? '',
    [RoutesId.legalClient]: legalClient?.fullName,
  };

  const onSubmit: FormSubmit<LegalClientBranchFormType> = async (
    values,
    helpers
  ) => {
    if (await updateLegalClientBranch(legalClientBranch!, values)) {
      helpers.setReadOnly(true);
    }
  };

  const onShowVersionHistory =
    legalClient &&
    legalClientBranch?.versionNumber &&
    legalClientBranch?.versionNumber > 1
      ? () => {
          navigate(
            ROUTES.clients.legalClients.legalClient.legalClientVersion
              .legalClientBranchVersion.fullPath,
            {
              legalClientId: legalClient.id,
              branchId: params.branchId!,
              versionId: legalClientBranch.versionId!,
            }
          );
        }
      : undefined;

  const onDelete = checkPermissions([PermissionType.LegalClientBranchDeleteOne])
    ? async (reason: string) => {
        if (
          await deleteLegalClientBranch({
            id: params.branchId!,
            reason,
            versionNumber: legalClientBranch?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientBranch.successDeleteMessage'));
          navigate(
            ROUTES.clients.legalClients.legalClient.fullPath,
            {
              legalClientId: params.legalClientId!,
            },
            { replace: true }
          );
        }
      }
    : undefined;

  const onArchive = !legalClientBranch?.archived
    ? async (reason: string) => {
        if (
          await archive(params.branchId!, {
            reason,
            versionNumber: legalClientBranch?.versionNumber!,
          })
        ) {
          notify.info(t('client.legal.legalClientBranch.archivedMessage'));
          return true;
        }
        return false;
      }
    : undefined;

  const onDearchive = legalClientBranch?.archived
    ? async (reason: string) => {
        const updatedHead = await dearchive(params.branchId!, {
          reason,
          versionNumber: legalClientBranch?.versionNumber!,
        });
        if (updatedHead) {
          notify.info(t('client.legal.legalClientBranch.dearchivedMessage'));
          return true;
        }
        return false;
      }
    : undefined;

  useServerErrorNotify(deleteError ?? archiveError ?? dearchiveError);

  useBranchPageTitle(legalClient, legalClientBranch);

  return {
    legalClientBranch,
    loading: !legalClientBranch && loading,
    countries: countries ?? [],
    countriesLoading,
    error,
    submitLoading,
    initialValues,
    breadcrumbsTitles,
    deleteLoading,
    archiveLoading,
    dearchiveLoading,
    lockClientIfPossible,
    unlockClient,
    onSubmit,
    onShowVersionHistory,
    onDelete,
    onArchive,
    onDearchive,
  };
};
