import { LegalClientFirstHeadEntity } from 'api/types/entity';
import { formatToISODate } from 'helpers';
import { TFunc } from 'libs/i18n';
import { LegalClientHeadType } from 'modules/client/common/forms/legalClientHeadPositionForm';
import {
  parseAddressValue,
  parseTaxResidencesValue,
  parsePepConnectionInitialValue,
  parseIdentityDocumentInitialValue,
  parseResidentialAddressValue,
} from 'modules/client/common/helpers';

import { LegalClientFirstHeadFormType } from '../../forms/legalClientFirstHeadForm';

import { parseDocumentsFiles } from './parseDocumentsFiles';

export const getLegalClientFirstHeadInitialValue = (
  client: LegalClientFirstHeadEntity,
  t: TFunc
): LegalClientFirstHeadFormType => ({
  contacts: {
    email: client.email,
  },

  reasonChange: { reason: '' },

  personalData: {
    firstName: client.firstName,
    lastName: client.lastName,
    middleName: client.middleName ?? '',
    dateOfBirth: client.dateOfBirth,
  },

  citizenships: client.citizenships ?? [null],

  taxResidences: parseTaxResidencesValue(client.taxResidences),

  registrationAddress: parseAddressValue(client.registrationAddress),
  residentialAddress: parseResidentialAddressValue(client.residentialAddress),

  pepConnection: parsePepConnectionInitialValue(client.pepConnection, t),

  position: {
    headType: {
      label: t(`client.legal.legalClientHead.position.headType.person`),
      value: LegalClientHeadType.person,
    },
    dateOfExpiration: client.position?.expirationDate,
    positionConfirmationFiles: client.position?.files ?? [],
    position: client.position?.position ?? '',
  },

  activity: {
    id: client.id,
    createdAt: formatToISODate(client.createdAt),
  },

  identityDocument: parseIdentityDocumentInitialValue(
    t,
    client.identityDocument
  ),
  additionalDocumentsFiles: client.additionalInformationFiles,
  documentsFiles: parseDocumentsFiles(client),
});
