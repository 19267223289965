import { CreateLegalClientFirstHeadParams } from 'api/types/params';
import { formatToISODate } from 'helpers';
import {
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseCitizenshipsParam,
  parseAddressWithFilesParam,
} from 'modules/client/common/helpers';

import { LegalClientFirstHeadFormType } from '../../forms/legalClientFirstHeadForm';

export const getLegalClientFirstHeadSubmitData = (
  legalClientLegalHeadId: string,
  versionNumber: number,
  values: LegalClientFirstHeadFormType
): CreateLegalClientFirstHeadParams => {
  const addressParam = parseAddressWithFilesParam(values);

  return {
    versionNumber,
    email: values.personalData?.email!,
    legalClientLegalHeadId,
    reason: values.reasonChange.reason,
    firstName: values.personalData!.firstName!,
    lastName: values.personalData!.lastName!,
    middleName: values.personalData!.middleName ?? null,
    dateOfBirth: values.personalData!.dateOfBirth!,
    citizenships: parseCitizenshipsParam(values),

    registrationAddress: addressParam.registrationAddress,
    residentialAddress: addressParam.residentialAddress,

    taxResidences: parseTaxResidencyParam(values),

    pepConnection: values.pepConnection.relation?.value!,
    connectedPepInfo: parsePepConnectionParam(values),

    positionInCompany: {
      positionInCompany: values.position.position,
      positionConfirmationFileIds:
        values.position.positionConfirmationFiles.map(({ id }) => id!),

      expirationDate: values.position.dateOfExpiration
        ? formatToISODate(values.position.dateOfExpiration)
        : undefined,
    },
  };
};
