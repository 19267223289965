import { useAdmins as useAdminsApi } from 'api/requests';
import { useQueryListData } from 'hooks';
import { FormSubmit } from 'libs/form/Form';
import { generatePath, ROUTES } from 'libs/navigation';
import { TableSetRowHref } from 'libs/ui';

import { CreateAdminFormType } from '../forms/createAdminForm';
import { AdminsListItem } from '../types';

import { useCreateAdmin } from './useCreateAdmin';

const LIMIT = 50;

export const useAdmins = () => {
  const { onSubmitCreate, createLoading, createFormError } = useCreateAdmin();

  const {
    list: admins,
    rowCount,
    loading,
    setFilter,
    sort,
    page,
    filter,
    refetch,
    error,
    onPaginationChange,
    onSortChange,
  } = useQueryListData(useAdminsApi, {
    limit: LIMIT,
    filterParams: ['search'],
  });

  const setRowHref: TableSetRowHref<AdminsListItem> = (admin) =>
    generatePath(ROUTES.admins.admin.fullPath, {
      adminId: admin.id,
    });

  const handleSubmitCreate: FormSubmit<CreateAdminFormType> = async (
    values,
    helpers
  ) => {
    if (await onSubmitCreate(values, helpers)) {
      refetch();
      return true;
    }
    return false;
  };

  return {
    admins,
    loading,
    sort,
    page,
    rowCount,
    limit: LIMIT,
    filter,
    createLoading,
    error,
    createFormError,
    onSubmitCreate: handleSubmitCreate,
    setFilter,
    setRowHref,
    onSortChange,
    onPaginationChange,
  };
};
