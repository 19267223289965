import { BasisForRepresentationEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { BasisForRepresentationFormType } from '../../forms/basisForRepresentationForm';

export const parseBasisForRepresentation = (
  data?: Maybe<BasisForRepresentationEntity>
): BasisForRepresentationFormType['basisForRepresentation'] =>
  data
    ? {
        documentName: data.documentName,
        dateOfExpiration: data.dateOfExpiration,
        files: data.files,
        dateOfIssue: data.dateOfIssue,
        documentNumber: data.documentNumber ?? '',
        notaryFullName: data.notary?.fullName ?? '',
        notaryLicenseInfo: data.notary?.licenseInfo ?? '',
      }
    : ({} as BasisForRepresentationFormType['basisForRepresentation']);
