import { FC } from 'react';

import { CountryEntity, LegalClientBodyEntity } from 'api/types/entity';
import { Stack } from 'libs/ui';
import {
  LegalClientHeadPositionSection,
  CompanyNameSection,
  CompanyRegistrationAddressSection,
  CompanyResidentialAddressSection,
  CompanyStateRegistrationSection,
  ActivitySection,
  AdditionalDocumentsSection,
} from 'modules/client/common/components/sections';

import {
  CompanyFirstHeadsSection,
  CompanyFirstHeadsSectionProps,
  CompanyTaxResidencySection,
} from '../sections';

export interface LegalClientLegalHeadSectionsProps
  extends CompanyFirstHeadsSectionProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  hideActivitySection?: boolean;
  bodies: LegalClientBodyEntity[];
}

export const LegalClientLegalHeadSections: FC<
  LegalClientLegalHeadSectionsProps
> = ({
  countries,
  countriesLoading,
  firstHeads,
  hideActivitySection,
  bodies,
  onClickHead,
  onClickAddFirstHead,
  onDeleteFirstHead,
  onArchiveFirstHead,
  onDearchiveFirstHead,
  setFirstHeadRowHref,
}) => (
  <Stack spacing={72}>
    {!hideActivitySection && <ActivitySection isBaseForm />}
    <CompanyNameSection />

    <LegalClientHeadPositionSection bodies={bodies} />

    <CompanyTaxResidencySection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyRegistrationAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyResidentialAddressSection
      countries={countries}
      countriesLoading={countriesLoading}
    />

    <CompanyStateRegistrationSection />

    <CompanyFirstHeadsSection
      firstHeads={firstHeads}
      setFirstHeadRowHref={setFirstHeadRowHref}
      onArchiveFirstHead={onArchiveFirstHead}
      onClickAddFirstHead={onClickAddFirstHead}
      onClickHead={onClickHead}
      onDearchiveFirstHead={onDearchiveFirstHead}
      onDeleteFirstHead={onDeleteFirstHead}
    />
    <AdditionalDocumentsSection />
  </Stack>
);
