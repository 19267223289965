import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  AdditionalDocumentsFormType,
  getAdditionalDocumentsSchema,
} from 'modules/client/common/forms/additionalDocumentsForm';
import {
  DocumentsFormType,
  documentsSchema,
} from 'modules/client/common/forms/documentsForm';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';

import { BaseActivityFormType, baseActivitySchema } from './baseActivityForm';
import { CitizenshipFormType, citizenshipsSchema } from './citizenshipForm';
import { ContactsFormType, getContactsSchema } from './contactsForm';
import {
  IdentityDocumentFormType,
  identityDocumentSchema,
} from './identityDocumentForm';
import {
  PepConnectionFormType,
  getPepConnectionSchema,
} from './pepConnectionForm';
import {
  PersonalDataFormType,
  getPersonalDataSchema,
} from './personalDataForm';
import {
  BaseLegalClientHeadPositionFormType,
  getBaseLegalClientHeadPositionFormSchema,
} from './positionInCompany';
import {
  RegistrationAddressFormType,
  registrationAddressSchema,
} from './registrationAddressForm';
import {
  ResidentialAddressFormType,
  residentialAddressWithFilesSchema,
} from './residentialAddressForm';
import { TaxResidencyFormType, taxResidencesSchema } from './taxResidencyForm';

export interface LegalClientFirstHeadFormType
  extends BaseActivityFormType,
    ContactsFormType,
    BaseLegalClientHeadPositionFormType,
    PersonalDataFormType,
    CitizenshipFormType,
    TaxResidencyFormType,
    RegistrationAddressFormType,
    ResidentialAddressFormType,
    IdentityDocumentFormType,
    PepConnectionFormType,
    AdditionalDocumentsFormType,
    DocumentsFormType,
    ReasonChangeFormType {}

export const getSchema = (
  t: TFunc
): ObjectSchema<LegalClientFirstHeadFormType> =>
  object()
    .concat(getContactsSchema(t))
    .concat(getPersonalDataSchema(t))
    .concat(citizenshipsSchema)
    .concat(baseActivitySchema)
    .concat(taxResidencesSchema)
    .concat(registrationAddressSchema)
    .concat(residentialAddressWithFilesSchema)
    .concat(identityDocumentSchema)
    .concat(reasonChangeSchema)
    .concat(getBaseLegalClientHeadPositionFormSchema(t))
    .concat(getPepConnectionSchema(t))
    .concat(getAdditionalDocumentsSchema())
    .concat(documentsSchema);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalClientFirstHeadFormType>();

export type FormErrors = FormErrorsBase<LegalClientFirstHeadFormType>;
