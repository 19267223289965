// eslint-disable-next-line no-restricted-imports
import {
  SavePositionInCompanyByAdminDto_Input,
  UpdateLegalClientHeadByAdminDto_Input,
} from 'api/generated/graphql';
import {
  BaseHeadPositionParam,
  HeadPositionParam,
} from 'api/types/params/headPositionParam';
import { formatToISODate } from 'helpers';

export const parseHeadPositionInCompanyParam = (
  positionInCompany: BaseHeadPositionParam
): SavePositionInCompanyByAdminDto_Input => ({
  positionInCompany: positionInCompany.positionInCompany,
  positionConfirmationFileIds: positionInCompany.positionConfirmationFileIds,
  expirationDate: positionInCompany.expirationDate
    ? formatToISODate(new Date(positionInCompany.expirationDate))
    : undefined,
});

export const parseHeadPositionParam = (
  position: HeadPositionParam
): UpdateLegalClientHeadByAdminDto_Input['position'] => ({
  ...parseHeadPositionInCompanyParam(position),
  isFirstHead: position.isFirstHead,
  legalClientBodyId: position.legalClientBodyId,
});
