import { LoadingLayout } from 'components';
import { useScrollToTop } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { useLegalClientBranchVersion } from '../hooks';
import { LegalClientBranchVersionView } from '../views';

export const LegalClientBranchVersionContainer = () => {
  const {
    initialValues,
    loading,
    error,
    isError,
    versions,
    breadcrumbsTitles,
    fullName,
    snapshotLoading,
    versionId,
    onBack,
    onChangeVersion,
    onCloseVersionHistory,
  } = useLegalClientBranchVersion();

  const { t } = useTranslation();

  useScrollToTop([]);

  if (loading) {
    return <LoadingLayout />;
  }

  return (
    <LegalClientBranchVersionView
      breadcrumbsTitles={breadcrumbsTitles}
      countries={[]}
      error={
        isError
          ? error?.localizedDescription ??
            t('client.legal.legalClientBranch.version.notVersionMessage')
          : undefined
      }
      fullName={fullName}
      initialValues={initialValues}
      snapshotLoading={snapshotLoading}
      versionId={versionId}
      versions={versions ?? []}
      onBack={onBack}
      onChangeVersion={onChangeVersion}
      onCloseVersionHistory={onCloseVersionHistory}
    />
  );
};
