import { useClientsCountries } from 'api/requests';
import { usePageTitleTranslation } from 'libs/navigation';

import { useClients } from '../hooks';
import { ClientsView } from '../views/ClientsView';

export const ClientsContainer = () => {
  const {
    clients,
    loading,
    sort,
    page,
    limit,
    rowCount,
    filter,
    setFilter,
    setRowHref,
    onSortChange,
    onPaginationChange,
  } = useClients();

  const { countriesData } = useClientsCountries();

  usePageTitleTranslation('client.clients.pageTitle');

  return (
    <ClientsView
      clients={clients}
      filter={filter}
      limit={limit}
      loading={!clients?.length && loading}
      locationCountries={countriesData?.location}
      page={page}
      registrationCountries={countriesData?.registration}
      rowCount={rowCount}
      setFilter={setFilter}
      setRowHref={setRowHref}
      sort={sort}
      onPaginationChange={onPaginationChange}
      onSortChange={onSortChange}
    />
  );
};
