import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useCreateLegalClientBranchMutation } from 'api/generated/graphql';
import {
  parseCreateLegalClientBranchParams,
  parseLegalClientBranchEntity,
  useError,
} from 'api/helpers';
import { CreateLegalClientBranchParams } from 'api/types/params';

export const useCreateLegalClientBranch = () => {
  const [handle, { data, loading, error: apolloError }] =
    useCreateLegalClientBranchMutation();

  const error = useError(apolloError);

  return {
    legalClientBranch: data?.createLegalClientBranch
      ? parseLegalClientBranchEntity(data.createLegalClientBranch)
      : undefined,
    loading,
    error,
    createLegalClientBranch: useCallback(
      async (params: CreateLegalClientBranchParams) => {
        const input = parseCreateLegalClientBranchParams(params);
        const res = await handle({ variables: { input } });
        return res.data?.createLegalClientBranch
          ? parseLegalClientBranchEntity(res.data?.createLegalClientBranch)
          : undefined;
      },
      [handle]
    ),
  };
};
