import { PermissionType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { usePermissions } from 'libs/permissions';
import { Table, TableSetRowHref } from 'libs/ui';

import { useColumns } from './columns';
import { BranchesItem, BranchOptionType } from './types';

interface Props {
  items: BranchesItem[];
  editable?: boolean;
  onCellClick?: (row: BranchesItem) => void;
  onClickDelete?: (row: BranchesItem) => void;
  onClickArchive?: (row: BranchesItem) => void;
  setRowHref?: TableSetRowHref<BranchesItem>;
}

export const BranchesTable = ({
  items,
  editable,
  onCellClick,
  onClickDelete,
  onClickArchive,
  setRowHref,
}: Props) => {
  const onClickOption = (item: BranchesItem, option: BranchOptionType) => {
    switch (option) {
      case BranchOptionType.delete:
        if (item.id) {
          onClickDelete?.(item);
        }
        break;
      case BranchOptionType.archive:
      case BranchOptionType.dearchive:
        if (item.id) {
          onClickArchive?.(item);
        }
        break;
      default:
        break;
    }
  };

  const { checkPermissions } = usePermissions();

  const shownOptions: Partial<Record<BranchOptionType, boolean>> = {
    archive: true,
    dearchive: true,
    delete: checkPermissions([PermissionType.LegalClientBranchDeleteOne]),
  };

  const columns = useColumns({ editable, shownOptions, onClickOption });

  const { t } = useTranslation();

  return (
    <Table
      hideFooter
      columns={columns}
      noRowsLabel={t('client.legal.companyBranches.noRowsLabel')}
      rows={items}
      setRowHref={setRowHref}
      onCellClick={onCellClick}
    />
  );
};
