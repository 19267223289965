import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  ReasonChangeFormType,
  reasonChangeSchema,
} from 'modules/client/common/forms/reasonChangeForm';

import { CompanyNameFormType, companyNameSchema } from './companyNameForm';
import {
  CompanyRegistrationAddressFormType,
  companyRegistrationAddressSchema,
} from './companyRegistrationAddressForm';
import {
  CompanyResidentialAddressFormType,
  companyResidentialAddressSchema,
} from './companyResidentialAddressForm';
import {
  CompanyStateRegistrationFormType,
  getCompanyStateRegistrationSchema,
} from './companyStateRegistrationForm';
import {
  CompanyTaxResidencyFormType,
  companyTaxResidencySchema,
} from './companyTaxResidencyForm';
import {
  LegalClientHeadPositionFormType,
  getLegalClientHeadPositionFormSchema,
} from './positionInCompanyForm';

export interface LegalClientLegalHeadFormType
  extends LegalClientHeadPositionFormType,
    CompanyNameFormType,
    CompanyTaxResidencyFormType,
    CompanyStateRegistrationFormType,
    CompanyRegistrationAddressFormType,
    CompanyResidentialAddressFormType,
    ReasonChangeFormType {}

export const getSchema = (
  t: TFunc
): ObjectSchema<LegalClientLegalHeadFormType> =>
  object()
    .concat(getLegalClientHeadPositionFormSchema(t))
    .concat(companyTaxResidencySchema)
    .concat(companyNameSchema)
    .concat(companyResidentialAddressSchema)
    .concat(companyRegistrationAddressSchema)
    .concat(reasonChangeSchema)
    .concat(getCompanyStateRegistrationSchema(t));

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalClientLegalHeadFormType>();

export type FormErrors = FormErrorsBase<LegalClientLegalHeadFormType>;
