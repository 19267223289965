import { UpdateLegalClientHeadParams } from 'api/types/params';
import { formatToISODate, yesOrNot } from 'helpers';
import {
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseCitizenshipsParam,
  parseAdditionalInformationFileIdsParam,
  parseAdditionalDocumentFileIdsParam,
  parseAddressWithFilesParam,
} from 'modules/client/common/helpers';
import { parseIdentityDocumentParam } from 'modules/client/common/helpers/parseParams/parseIdentityDocumentParam';

import { LegalClientHeadFormType } from '../../forms/legalClientHeadForm';

export const getLegalClientHeadSubmitData = (
  versionNumber: number,
  values: LegalClientHeadFormType
): UpdateLegalClientHeadParams => {
  const addressParam = parseAddressWithFilesParam(values);

  return {
    versionNumber,
    reason: values.reasonChange.reason,
    firstName: values.personalData.firstName,
    lastName: values.personalData.lastName,
    middleName: values.personalData.middleName ?? null,
    dateOfBirth: values.personalData.dateOfBirth,
    citizenships: parseCitizenshipsParam(values),

    registrationAddress: addressParam.registrationAddress,
    residentialAddress: addressParam.residentialAddress,

    taxResidences: parseTaxResidencyParam(values),

    pepConnection: values.pepConnection.relation?.value!,
    connectedPepInfo: parsePepConnectionParam(values),

    additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
      values.additionalDocumentsFiles
    ),
    additionalDocumentFileIds: parseAdditionalDocumentFileIdsParam(
      values.documentsFiles
    ),

    identityDocument: parseIdentityDocumentParam(values),

    position: {
      positionInCompany: values.position.position,
      isFirstHead: yesOrNot(values.position.firstHead?.value) ?? false,
      legalClientBodyId: values.position.body?.value!,
      expirationDate: values.position.dateOfExpiration
        ? formatToISODate(values.position.dateOfExpiration)
        : undefined,
      positionConfirmationFileIds:
        values.position.positionConfirmationFiles.map(({ id }) => id!),
    },
  };
};
