// eslint-disable-next-line no-restricted-imports
import { CreateLegalClientBranchByAdminDto_Input } from 'api/generated/graphql';
import { CreateLegalClientBranchParams } from 'api/types/params';

export const parseCreateLegalClientBranchParams = (
  params: CreateLegalClientBranchParams
): CreateLegalClientBranchByAdminDto_Input => ({
  versionNumber: params.versionNumber,
  reason: params.reason,
  legalClientId: params.legalClientId,
  fullName: params.fullName,
  legalAddress: params.legalAddress,
  actualAddress: params.actualAddress,
  contacts: {
    email: params.email,
    phoneNumber: params.phone,
  },
  taxResidences: params.taxResidences,
  stateRegistration: params.stateRegistration,
});
