import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { DocumentsTableFeature } from 'modules/client/common/feature';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/client/common/helpers';

import {
  Field,
  useFormContext,
} from '../../../../forms/basisForRepresentationForm';

export const FormContent = () => {
  const { t } = useTranslation();
  const { readOnly } = useFormContext();

  return (
    <Stack spacing={24}>
      <Field.Text
        label={t('client.client.basisForRepresentation.documentName.label')}
        name="basisForRepresentation.documentName"
        placeholder={t(
          'client.client.basisForRepresentation.documentName.placeholder'
        )}
      />
      <Field.Text
        label={t('client.client.basisForRepresentation.documentNumber.label')}
        name="basisForRepresentation.documentNumber"
        placeholder={t(
          'client.client.basisForRepresentation.documentNumber.placeholder'
        )}
      />
      <Field.DatePicker
        label={t('client.client.basisForRepresentation.registrationDate.label')}
        maxDate={getToday()}
        name="basisForRepresentation.dateOfIssue"
        placeholder={t(
          'client.client.basisForRepresentation.registrationDate.placeholder'
        )}
      />
      <Field.DatePicker
        disableFuture={false}
        label={t('client.client.basisForRepresentation.expirationDate.label')}
        minDate={getTodayWithResetHours()}
        name="basisForRepresentation.dateOfExpiration"
        placeholder={t(
          'client.client.basisForRepresentation.expirationDate.placeholder'
        )}
      />

      <Field.Text
        label={t('client.client.basisForRepresentation.notaryFullName.label')}
        name="basisForRepresentation.notaryFullName"
        placeholder={t(
          'client.client.basisForRepresentation.notaryFullName.placeholder'
        )}
      />

      <Field.Text
        label={t(
          'client.client.basisForRepresentation.notaryLicenseInfo.label'
        )}
        name="basisForRepresentation.notaryLicenseInfo"
        placeholder={t(
          'client.client.basisForRepresentation.notaryLicenseInfo.placeholder'
        )}
      />

      <DocumentsTableFeature
        name="basisForRepresentation.files"
        readOnly={readOnly}
      />
    </Stack>
  );
};
