import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/client/legal/forms/companyActivitiesForm';

interface Props {
  index: number;
}

export const Fields = ({ index }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Text
        label={t('client.legal.companyActivities.activity.label')}
        name={`activityTypes.${index}.name`}
        placeholder={t('client.legal.companyActivities.activity.placeholder')}
      />

      <Field.Text
        label={t('client.legal.companyActivities.activityCode.label')}
        name={`activityTypes.${index}.code`}
        placeholder={t(
          'client.legal.companyActivities.activityCode.placeholder'
        )}
      />
    </>
  );
};
