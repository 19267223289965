// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_LegalClientLegalHeadFirstHeadController_UpdateById_Input_PepConnectionType as PepConnectionTypeApi,
  UpdateLegalClientLegalHeadFirstHeadByAdminDto_Input,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { UpdateLegalClientFirstHeadParams } from 'api/types/params';
import { formatToISODate } from 'helpers';

import {
  parseIdentityDocumentParam,
  parseHeadPositionInCompanyParam,
} from '../parseParam';

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi.Self,
};

export const parseUpdateLegalClientFirstHeadParams = (
  params: UpdateLegalClientFirstHeadParams
): UpdateLegalClientLegalHeadFirstHeadByAdminDto_Input => ({
  versionNumber: params.versionNumber,
  firstName: params.firstName,
  lastName: params.lastName,
  middleName: params.middleName,
  dateOfBirth: formatToISODate(params.dateOfBirth),
  citizenships: params.citizenships,
  reason: params.reason,

  registrationAddress: params.registrationAddress,
  residentialAddress: params.residentialAddress,
  taxResidences: params.taxResidences,

  connectedPepInfo: params.connectedPepInfo,

  pepConnectionType: relationType[params.pepConnection],

  positionInCompany: parseHeadPositionInCompanyParam(params.positionInCompany),
  additionalInformationFileIds: params.additionalInformationFileIds,
  additionalDocumentFileIds: params.additionalDocumentFileIds,

  identityDocument: parseIdentityDocumentParam(params.identityDocument),
});
