import { FC, ReactNode } from 'react';

import { ClientOnboardingStatusType } from 'api/types/entity';
import { ToggleButtonProps } from 'libs/ui';

interface TabBarProps<T extends string | number>
  extends Pick<ToggleButtonProps<T>, 'value' | 'onChange'> {
  onboardingStatus?: ClientOnboardingStatusType;
}

export enum ActionType {
  showVersionHistory = 'showVersionHistory',
  edit = 'edit',
  changeOnboardingStatus = 'changeOnboardingStatus',
  changeAccountStatus = 'changeAccountStatus',
  delete = 'delete',
  changeEmail = 'changeEmail',
  archive = 'archive',
  dearchive = 'dearchive',
  revokeOtpVerification = 'revokeOtpVerification',
}

export type ActionProps = Partial<{
  showEditAction: boolean; // default true
  onChangeOnboardingStatus: () => void;
  onChangeAccountStatus: () => void;
  onDelete: () => void;
  onArchive: () => void;
  onDearchive: () => void;
  onChangeEmail: () => void;
  onRevokeOtpVerification: () => void;
}>;

export interface FormContentWrapperProps<
  T extends string | number,
  Path extends string
> {
  content: ReactNode;
  shownTabBar: boolean;
  fullName: string;
  tabBarValue?: T;
  submitLoading?: boolean;
  TabBarComponent?: FC<TabBarProps<T>>;
  actionMenuProps?: ActionProps;
  topContent?: ReactNode;
  headerRightContent?: ReactNode;
  backPath?: Path;
  onChangeTabBarValue?: (v: T) => void;
  onEnableReadOnly: () => void;
  resetForm: () => void;
  onEdit: () => void;
  onShowVersionHistory?: () => void;
}
