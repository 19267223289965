import { FC } from 'react';

import { CountryEntity } from 'api/types/entity';
import { useFormErrorFocus } from 'hooks';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  ReasonAddSection,
  CompanyRegistrationAndResidentialAddressSection,
  CompanyStateRegistrationSection,
  ContactsSection,
} from 'modules/client/common/components/sections';
import { CompanyTaxResidencySection } from 'modules/client/legal/components/sections';

import { NameSection } from '../sections';

export interface LegalClientAddBranchSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const LegalClientAddBranchSections: FC<
  LegalClientAddBranchSectionsProps
> = ({ countries, countriesLoading }) => {
  const { t } = useTranslation();

  const { readOnly } = useFormContext();

  useFormErrorFocus('center');

  return (
    <Stack spacing={48}>
      <ReasonAddSection />

      <NameSection />

      <CompanyTaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <CompanyStateRegistrationSection hideDocuments />

      <CompanyRegistrationAndResidentialAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
        title={t('client.legal.legalClientBranch.address.title')}
      />

      <ContactsSection
        readOnly={readOnly}
        title={t('client.legal.legalClientBranch.contacts.title')}
      />
    </Stack>
  );
};
