export interface BranchesItem {
  id: string;
  name: string;
  city: string;
  location: string;
  archived: boolean;
}

export enum BranchOptionType {
  delete = 'delete',
  archive = 'archive',
  dearchive = 'dearchive',
}

export interface BranchColumnArgs {
  shownOptions?: Partial<Record<BranchOptionType, boolean>>;
  editable?: boolean;
  onClickOption: (item: BranchesItem, option: BranchOptionType) => void;
}
