import { TypedForm, ObjectSchema, string, object, array } from 'libs/form';

export interface CompanyActivityFormType {
  name: string;
  code?: string;
}

export interface CompanyActivitiesFormType {
  activityTypes: CompanyActivityFormType[];
  OKPOCode?: string;
}

export const companyActivitiesSchema: ObjectSchema<CompanyActivitiesFormType> =
  object({
    OKPOCode: string(),
    activityTypes: array()
      .of(
        object({
          name: string().required().max(200),
          code: string().max(200),
        }).required()
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyActivitiesFormType>();
